import { useEffect, useRef } from 'react';

import { css, cva } from '@headout/pixie/css';

import { strings } from 'Constants/strings';

import TYPE_LABELS from 'Static/typography/labels';

const container = css({
	position: 'absolute',
	left: '1rem',
	height: '1.25rem',
	overflow: 'hidden',
	pointerEvents: 'none',
	display: 'flex',
	marginTop: '0.0625rem',

	'@media (max-width: 768px)': {
		color: 'semantic.text.grey.3',
		marginTop: '0.1875rem',
	},
});

const scrollingContainer = css({
	transform: 'translateY(0)',
	transition: 'transform 0.6s ease-in-out',
	display: 'flex',
	flexDirection: 'column',
});

const placeholderItem = cva({
	base: {
		color: 'semantic.text.grey.3',
		marginBottom: 'space.2',
		transition: 'opacity 0.4s ease-in-out',
		opacity: 'opacity.0',
		marginRight: 'space.4',

		_first: {
			opacity: 'opacity.100',
		},

		'@media (max-width: 768px)': {
			marginRight: 'space.3',
		},
	},
	variants: {
		typeLabel: {
			regular: {
				textStyle: 'Semantics/UI Label/Regular',
			},
			medium: {
				textStyle: 'Semantics/UI Label/Medium',
			},
		},
	},
});

type SearchPlaceholderProps = {
	itemFontLabel: string;
};

const getTransformOffset = (itemFontLabel: string) => {
	switch (itemFontLabel) {
		case TYPE_LABELS.UI_LABEL_MEDIUM:
			return 20;
		case TYPE_LABELS.UI_LABEL_REGULAR:
			return 16;
		default:
			return 20;
	}
};

const SearchPlaceholder = ({ itemFontLabel }: SearchPlaceholderProps) => {
	const SEARCH_PLACEHOLDER_ITEMS = [
		strings.SEARCHBAR_TEXTS.EXPERIENCES_AND_CITIES,
		strings.SEARCHBAR_TEXTS.BURJ_KHALIFA,
		strings.SEARCHBAR_TEXTS.DUBAI,
		strings.SEARCHBAR_TEXTS.VATICAN_MUSEUMS,
		strings.SEARCHBAR_TEXTS.ROME,
		strings.SEARCHBAR_TEXTS.EIFFEl_TOWER,
		strings.SEARCHBAR_TEXTS.PARIS,
		strings.SEARCHBAR_TEXTS.EXPERIENCES_AND_CITIES,
	];

	const scrollingContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const list = scrollingContainerRef.current;
		if (!list) return;

		const transformOffset = getTransformOffset(itemFontLabel);
		let animationInterval: NodeJS.Timer | undefined,
			index = 0,
			lastTime = 0;

		const animateListItems = (time: number) => {
			const currentItem: HTMLSpanElement = list.children[
				index
			] as HTMLSpanElement;
			const nextItem: HTMLSpanElement = list.children[
				index + 1
			] as HTMLSpanElement;

			// animation runs after every 2s till the last list item
			if (!lastTime || time - lastTime >= 2000) {
				currentItem.style.opacity = '0';
				setTimeout(() => (nextItem.style.opacity = '1'), 250);

				const listStyles = window.getComputedStyle(list);
				const matrix = listStyles.transform;
				const currentTop = matrix.split(',')[5]; // translateY is the 6th value in the transform matrix

				list.style.transform = `translateY(${
					parseInt(currentTop) - transformOffset - 2
				}px)`;

				lastTime = time;
				index++;
			}

			animationInterval = setInterval(() => {
				if (index !== SEARCH_PLACEHOLDER_ITEMS.length - 1) {
					requestAnimationFrame(animateListItems);
				} else {
					clearInterval(animationInterval);
				}
			}, 2000);
		};

		requestAnimationFrame(animateListItems);

		() => {
			clearInterval(animationInterval);
		};
	}, []);

	return (
		<div className={container}>
			<span
				className={placeholderItem({
					typeLabel:
						itemFontLabel === TYPE_LABELS.UI_LABEL_REGULAR
							? 'regular'
							: 'medium',
				})}
				role={'presentation'}
				aria-hidden={'true'}
			>
				{strings.SEARCHBAR_TEXTS.SEARCH_FOR}{' '}
			</span>
			<div
				className={scrollingContainer}
				ref={scrollingContainerRef}
				role={'presentation'}
				aria-hidden={'true'}
			>
				{SEARCH_PLACEHOLDER_ITEMS.map((item, index) => (
					<span
						className={placeholderItem({
							typeLabel:
								itemFontLabel === TYPE_LABELS.UI_LABEL_REGULAR
									? 'regular'
									: 'medium',
						})}
						key={index}
					>
						{item}
					</span>
				))}
			</div>
		</div>
	);
};

export default SearchPlaceholder;
